import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import styles from './styles.module.css'

export const query = graphql`
  query AllFAQ {
    general: allMarkdownRemark(
      filter: {frontmatter: {date: {glob: "general*"}}}
      sort: {order: ASC, fields: [frontmatter___date]}
      limit: 1000
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt
          frontmatter {
            path
            date
            title
          }
        }
      }
    }

    connections: allMarkdownRemark(
      filter: {frontmatter: {date: {glob: "connections*"}}}
      sort: {order: ASC, fields: [frontmatter___date]}
      limit: 1000
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt
          frontmatter {
            path
            date
            title
          }
        }
      }
    }

    accounts: allMarkdownRemark(
      filter: {frontmatter: {date: {glob: "account*"}}}
      sort: {order: ASC, fields: [frontmatter___date]}
      limit: 1000
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt
          frontmatter {
            path
            date
            title
          }
        }
      }
    }
  }
`

const IndexPage = ({data}) => {
  const general = data.general.edges
  const connections = data.connections.edges
  const accounts = data.accounts.edges

  const renderQuestion = post => (
    <>
      <h3 className={styles.postLink}>
        <Link to={post.node.frontmatter.path}>
          {post.node.frontmatter.title}
        </Link>{' '}
      </h3>
    </>
  )

  return (
    <>
      <Layout>
        <SEO title="FAQ" />
        <div className={styles.container}>
          <h1>Frequently Asked Questions</h1>
          <h4>General</h4>
          {general.map(renderQuestion)}
          <h4>Account</h4>
          {accounts.map(renderQuestion)}
          <h4>Connections</h4>
          {connections.map(renderQuestion)}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
